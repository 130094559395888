import * as React from "react"
import EmailForm from "../components/EmailRegister"
import Layout from "../components/Layout"
import MissingPage from '../images/404.svg'

const NotFoundPage = () => {
  return (
    <Layout>
      <title>Not found</title>
      <div className="flex justify-center mt-10 flex-col items-center">
        <h1 className="text-2xl md:text-6xl font-teko">Ruh oh - Seems you got lost - there is nothing here.</h1>
        <img src={MissingPage} alt="404" className="h-96" />
        <h1 className="text-xl font-teko">Don't worry, you can still sign up to our Newsletter below</h1>
        <EmailForm />
      </div>
    </Layout>
  )
}

export default NotFoundPage
